<template>
	<div class="page">
		<lm-header :scrollTop="scrollTop" />
		<div class="banner">
			<div class="layout content">
				<div class="title">专业服务，创造价值</div>
				<div class="button" @click="() => {$router.push('/relation')}">立即体验</div>
			</div>
		</div>
		<div class="main">
			<div class="card">
        <img src="./assets/定制方案ic@2x.png" >
        <div class="title">定制方案</div>
        <div class="info">专业客服经理实时在线，一对一服务，为您解答系统功能的方案设计问题</div>
      </div>
      <div class="card">
        <img src="./assets/极速响应ic@2x.png" >
        <div class="title">极速响应</div>
        <div class="info">当您遇到问题、需求和挑战时，我们都有专人为您服务，保障您问题的及时有效解决.</div>
      </div>
      <div class="card">
        <img src="./assets/上门拜访ic@2x.png" >
        <div class="title">上门拜访</div>
        <div class="info">达成意向后，会由专业的顾问经理上门拜访，了解您的系统及功能需求，解答疑问。</div>
      </div>
      <div class="card">
        <img src="./assets/技术支持ic@2x.png" >
        <div class="title">技术支持</div>
        <div class="info">产品上线后可安排上门部署，提供专业的线下培训服务保障，确保客户使用顺畅。</div>
      </div>
      <div class="card">
        <img src="./assets/售后保障ic@2x.png" >
        <div class="title">售后保障</div>
        <div class="info">服务问题及时解决与反馈，保障客户软件稳定运行</div>
      </div>
		</div>
		<lm-footer />
	</div>
</template>

<script>
import LmHeader from "@/components/header";
import LmFooter from "@/components/footer";
export default {
	components: {
		LmHeader,
		LmFooter
	},
	data() {
		return {
			scrollTop: 0,
		};
	},
	mounted() {
		window.addEventListener("scroll", this.getScroll);
		this.getScroll();
	},
	methods: {
		getScroll() {
			this.scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
		},
	},
};
</script>

<style lang="less" scoped>
.banner {
	width: 1920px;
	height: 450px;
	background: url("./assets/banner.png") no-repeat;
	background-size: 1920px;
	position: relative;
	.content {
		padding-top: 155px;
		line-height: 1;
		text-align: left;
		color: #ffffff;
		.title {
			font-size: 50px;
			padding-bottom: 30px;
		}
		.info {
			font-size: 22px;
			padding-bottom: 53px;
		}
		.button {
			display: inline-block;
			font-size: 18px;
			padding: 15px 34px;
			background: #ff9000;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.main {
  width: 1240px;
  margin: 0 auto;
  padding: 60px 0 100px;
  display: flex;
  .card {
    width: 240px;
    padding: 60px 23px 0 42px;
		text-align: left;
    img {
      width: 140px;
      margin-bottom: 60px;
			margin-left: 8px;
    }
    .title {
      font-size: 28px;
      line-height: 1;
      margin-bottom: 25px;
      text-align: left;
    }
    .info {
      font-size: 18px;
      color: #666666;
      line-height: 1.5;
      text-align: justify;
    }
  }
}
</style>
